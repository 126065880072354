<template>
  <ul class="navbarNavv">
    <li class="navItem">
      <a @click="goToRoute('/')" class="navLInk">Home</a>
    </li>
    <li class="navItem">
      <a @click="goToRoute('/other-services')" class="navLInk">Other Services</a>
    </li>
    <li class="navItem">
      <a @click="goToRoute('/doctors')" class="navLInk">Doctors</a>
    </li>
    <li class="navItem">
      <a @click="goToRoute('/videos')" class="navLInk">Videos</a>
    </li>
    <li class="navItem">
      <a @click="goToRoute('/more')" class="navLInk">More</a>
    </li>
    <li class="sm:hidden">
      <button
          @click="goToSubscription"
          class="subscribeNowBtn"
      >
        Subscribe Now
      </button>
    </li>
  </ul>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "NavList",
  props: ["onClick"],
  methods: {
    goToSubscription: async function () {
      this.onClick();
      this.$router.push('/subscription')
    },
    goToRoute: async function (path) {
      this.onClick();
      window.location.replace(`https://care.healthplus.life${path}`);
    },
  }
})
</script>

<style lang="scss">
.navbarNavv {
  @apply flex flex-col xlMax:px-4 xlMax:gap-6 xl:flex-row flex-wrap gap-8;
}

.navItem {
  @apply cursor-pointer text-hp-lead text-hp-blue-100
}

.navItem.activeLink {
  @apply text-hp-lead text-hp-blue-100 border-b-2 border-b-hp-blue-100 #{!important};
}

.navLInk {
  @apply flex gap-1 items-center;
}

.subscribeNowBtn {
  @apply flex justify-center items-center bg-hp-green-100 py-4 px-8
  text-hp-blue-100 text-hp-lead rounded-[40px]
}

</style>