<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :color="'#BCEDB6'" :background-color="'#000'"
             :loader="loader" :lock-scroll="true"/>
    <section class="heroSection">
      <div class="container">
        <div class="heroSectionBody">
          <div>
            <h1 class="heroSectionBodyHeading">
              Complete Health Care Solution for Everyone
            </h1>
            <p class="heroSectionBodyText">
              Our professional team will take care of you, we value your time
              and health.Our professional team will take care of you.
            </p>
            <button
                @click="push(`/doctors`)"
                class="appointmentBtn"
            >
              Call To Specialist Doctors
              <i class="bi bi-arrow-right-short ms-5"></i>
            </button>
          </div>
          <div>
            <picture>
              <img
                  src="../../../assets/images/hero-section-banner.png"
                  alt="hero-section-banner"
                  class="w-full"
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-hp-gray-100 py-12 w-full">
      <div class="container">
        <div class="specialistDoctors">
          <div class="specialistDoctorsHeader">
            <h1 class="specialistDoctorsTitle">
              Specialist Doctors in Live Now
            </h1>
            <a>
              <button class="specialistDoctorsBtn">
                See All
                <i class="bi bi-arrow-right-short ms-3"></i>
              </button>
            </a>
          </div>

        </div>
        <Carousel class="carousel" v-bind="settings" :breakpoints="breakpoints">
          <Slide v-for="(item, index) in activeDoctors" :key="index">
            <div class="carousel__item !w-full !h-full">
              <div
                  class="block h-full cursor-pointer"
              >
                <div class="specialistDoctorsGridCard">
                  <span
                      class="activeDoctor" :class="[item.isActive ? 'active' : '']"
                  ></span>

                  <div class="specialistDoctorsGridCardProfileBox">
                    <img
                        :src="$IMAGE_BASE_URL+'/'+item.avatar"
                        class="w-full h-full rounded-2xl"
                        alt=""/>
                  </div>
                  <div class="mb-2">
                    <h1 class="specialistDoctorsName">
                      {{ item.name }}
                    </h1>
                    <p class="specialistDoctorsDegree line-clamp-1">
                      {{ item?.degree }}
                    </p>
                    <p class="speciality">
                      {{ item.specialities[0]?.speciality_name }}
                    </p>
                  </div>
                  <p class="specialistDoctorsFee">
                    <i class="bi bi-camera-video text-blue-600"></i>
                    {{ " " }}
                    {{ item.video_call_cost }}
                  </p>
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>
    </section>

  </div>
</template>
<script src="../js/home.js">
</script>


<style lang="scss" scoped>
@import "../css/home.scss";
</style>
