import Loading from "vue-loading-overlay";
import {
    Carousel,
    Navigation,
    Pagination,
    Slide,
} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'HomePage',
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'dots',
            activeDoctors: [],
            settings: {
                itemsToShow: 1,
                snapAlign: 'start',
            },
            breakpoints: {
                // 700px and up
                640: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                768: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
                1280: {
                    itemsToShow: 5,
                    snapAlign: 'center',
                },
            },
        }
    },
    components: {
        Loading,
        Carousel,
        Slide,
        Navigation,
        Pagination,
    },
    async created() {
        await this.getMsisdn();
        await this.getAllDoctor();
    },

    methods: {
        getMsisdn: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$BASE_URL + this.$V3 + 'user-info/msisdn'
            }
            // this.isLoading = true
            this.$axios(config).then(response => {
                this.isLoading = false;
                console.log('kkk=', response)
                if (response.status === 200) {
                    if (response.data.found === true) {
                        localStorage.setItem("msisdn_found", response.data.found);
                        localStorage.setItem("user_msisdn", response.data.msisdn);
                        window.location.replace(
                            `https://care.healthplus.life?found=${response.data.found}&msisdn=${response.data.msisdn}`
                        );
                    } else {
                        window.location.replace(`https://care.healthplus.life`);
                    }
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        getAllDoctor: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$BASE_URL2 + this.$V6 + 'doctor'
            }
            // this.isLoading = true
            this.$axios(config).then(response => {
                this.isLoading = false;
                if (response.status === 200) {
                    this.activeDoctors = response.data.data.filter((doctor) => doctor.isActive);
                    console.log('getAllDoctor=', response.data.data)
                    console.log('this.activeDoctors=', this.activeDoctors)
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        }
    }
}

