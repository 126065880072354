import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import moment from 'moment';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'vue-loading-overlay/dist/css/index.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import mixin from './mixin'

const app = createApp(App);
app.config.globalProperties.$axios = axios;

app.config.globalProperties.$V1 = '/v1/';
app.config.globalProperties.$V2 = '/v2/';
app.config.globalProperties.$V3 = '/v3/';
app.config.globalProperties.$V4 = '/v4/';
app.config.globalProperties.$V5 = '/v5/';
app.config.globalProperties.$V6 = '/v6/';
if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    // app.config.globalProperties.$base = 'http://localhost:8080'
    app.config.globalProperties.$BASE_URL = 'http://healthplus.life:8080';
    app.config.globalProperties.$BASE_URL2 = 'http://api.healthplus.life/api';
    app.config.globalProperties.$IMAGE_BASE_URL = 'http://api.healthplus.life';
} else {
    app.config.globalProperties.$base = 'http://healthplus.life'
    app.config.globalProperties.$BASE_URL = 'http://healthplus.life:8080';
    app.config.globalProperties.$BASE_URL2 = 'http://api.healthplus.life/api';
    app.config.globalProperties.$IMAGE_BASE_URL = 'http://api.healthplus.life';
}

app.config.globalProperties.$filters = {
    formatDate(date) {
        return moment(String(date)).format('DD MMM. YYYY');
    },
    formatBlogDate(date) {
        return moment(String(date)).format('DD/MM/yyyy');
    },
    formatReviewDate(date) {
        return moment(date).fromNow();
    }
};


app.use(VueSweetalert2);
app.mixin(mixin);
app.use(router).mount('#app')