<script>
import Loading from "vue-loading-overlay";

export default {
  name: "OnDemandPackSubscription",
  components: {Loading},
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: 'spinner',
      ondemandPackageData: []
    }
  },
  async created() {
    console.log('pp=',this.$route.fullPath);
    // if (!localStorage.getItem('user_msisdn')){
    //   localStorage.setItem("user_msisdn", this.$route.query.msisdn);
    // }
    localStorage.setItem("user_msisdn", this.$route.query.msisdn);
    await this.getMsisdn();
    await this.getOndenandPackages();
  },

  methods: {
    getMsisdn: async function () {
      this.isLoading = true;
      let config = {
        method: "GET",
        url: this.$BASE_URL + this.$V3 + 'user-info/msisdn'
      }
      // this.isLoading = true
       this.$axios(config).then(response => {
        if (response.status === 200) {
          if (response.data.found === true) {
            this.isLoading = false;
            localStorage.setItem("msisdn_found", response.data.found);
            localStorage.setItem("user_msisdn", response.data.msisdn);
            window.location.replace(`https://care.healthplus.life/login?promo=${this.$route.fullPath}`)
            // this.postLog()
          } else {
            this.isLoading = false;
            if (!this.$route.query.msisdn){
              window.location.replace(`https://care.healthplus.life/login?promo=${this.$route.fullPath}`)
            } else {
              localStorage.setItem("user_msisdn", this.$route.query.msisdn);
              this.postLog()
            }
          }
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      })
    },
    getOndenandPackages: async function () {
      let config = {
        method: "GET",
        url: this.$BASE_URL2 + this.$V6 + `packages/ondemand?package=${this.$route.query.package}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "application/json",
          msisdn: `${localStorage.getItem('user_msisdn')}`,
        },
      }
      // this.isLoading = true
      this.$axios(config).then(response => {
        this.isLoading = false;
        // console.log('kkk=', response)
        if (response.status === 200) {
          this.ondemandPackageData = response.data.data;
          // console.log('kkk=', response.data.data)
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      })
    },
    OndemandSubscribe: async function (param) {
      this.isLoading = true;
      const msisdn = localStorage.getItem("user_msisdn");
      const myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      myHeaders.append("msisdn", msisdn);
      let config = {
        method: "POST",
        url: this.$BASE_URL + this.$V1 + 'subscriptions/ondemand-subscribe/' + param.packageName,
        headers: {msisdn: msisdn}
      }
      this.$axios(config).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          window.location.replace(`https://care.healthplus.life`);
        }else {
          window.location.replace(`https://care.healthplus.life`);
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
        window.location.replace(`https://care.healthplus.life`);
      })
    },
    postLog: async function () {
      const msisdn = localStorage.getItem("user_msisdn");
      // const myHeaders = new Headers();
      // myHeaders.append("msisdn", msisdn);
      let data = {
        msisdn: msisdn,
        channel: "web",
        device_token: "",
        device_name: navigator.userAgent,
        referer: "promo",
        page: this.$route.fullPath,
      }
      let config = {
        method: "POST",
        url: this.$BASE_URL2 + '/access-log',
        headers: {
          "Content-Type": "application/json",
        },
        data: data
      }
      this.$axios(config).then(response => {
        this.isLoading = false;
        console.log(response.status)
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      })
    }
  }
}
</script>

<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader"/>
  <section class="py-4 container">
    <h1 class="text-hp-display-xs lg:text-hp-display-lg mb-5">
      On-Demand Pack
    </h1>
    <div v-if="ondemandPackageData.length" class="grid grid-cols-1 gap-5" :class="`sm:grid-cols-${ondemandPackageData.length}`">
      <div v-for="(elem) in ondemandPackageData"
           :key="elem?.id"
           class=" bg-white hover:bg-[#BCEDB6] p-5 rounded-md shadow-sm hover:shadow-lg  transition-all duration-500"
      >
        <p class="bg-[#BCEDB6] border-2 border-white font-semibold px-6 py-2 text-sm md:text-lg text-center rounded-lg">
          {{ elem?.packageDisplay }}
        </p>
        <ul class="text-center">
         <li v-for="(x) in elem?.details.split('\n')" :key="x">{{x}}</li>
        </ul>
        <p class=" text-blue-900 text-sm md:text-lg font-medium pt-10 px-5 text-center">
          {{ elem?.priceDisplay }} On Demand
        </p>
        <p class=" pb-10 text-sm text-center">
          Package Validity {{ elem.packageValidity }}{{ " " }}
          {{ elem.packageValidity > 1 ? "days" : "day" }}
        </p>
        <div class="flex justify-center">
          <button
              @click="OndemandSubscribe(elem)"
              class=" px-10 py-2 bg-[#0F2851] rounded-xl text-white text-sm md:text-lg  font-semibold  "
          >
          Press OK
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>