<template>
  <div class="header">
    <header class="flex items-center">
      <nav class="container navbar">
        <a class="navbar-brand p-0 " href="/">
          <img class="bg-white" src="../../assets/images/HealthPluslogo.png"
               width="46" height="46" alt="logo">
        </a>

        <div class="offcanvas" :class="[toggleViewMode?'show':'']"
        >
          <div class="offcanvasHeader">
            <img class="bg-white" src="../../assets/images/HealthPluslogo.png"
                 width="40" height="40" alt="logo">
            <button @click="toggleViewMode = !toggleViewMode"
                    class="offcanvasCloseBtn"
            >
              <i class="bi bi-chevron-right"></i>

            </button>
          </div>

          <div class="offcanvasBody">
            <NavList :onClick="onClick"/>
            <div class="hidden xl:flex xl:flex-col">
              <div class="flex gap-[14px]">
                <button @click="goToRoute"
                        class="callBtn"
                >
                  <img src="../../assets/images/a-call.png" alt="">
                </button>
                <button @click="goToRoute"
                        class="callBtn"
                >
                  <img src="../../assets/images/v_call.png" alt="">
                </button>
              </div>
              <div class="text-hp-blue-100 text-hp-body-p5-hi pt-1.5 text-center">
                Call to doctor
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <ul class='navbarNav'>
            <li class="flex flex-col xl:hidden">
              <div class="flex gap-[14px]">
                <button @click="goToRoute"
                        class="callBtn"
                >
                  <img src="../../assets/images/a-call.png" alt="">
                </button>
                <button @click="goToRoute"
                        class="callBtn"
                >
                  <img src="../../assets/images/v_call.png" alt="">
                </button>
              </div>
              <div class="text-hp-blue-100 text-hp-body-p5-hi pt-1.5 text-center">
                Call to doctor
              </div>
            </li>
            <li class="hidden">
              <button class="searchBtn">

              </button>
            </li>
            <li class="hidden sm:block">
              <a
                  href="javascript:void(0)"
                  @click="$router.push('/subscription')">
                <button class="subscribeNowBtn">
                  Subscribe Now
                </button>
              </a>
            </li>
            <li class="xl:hidden">
              <button @click="toggleViewMode = !toggleViewMode" class="px-3 py-2 bg-white text-black">
                <i class="bi bi-list text-black"></i>
              </button>

            </li>
          </ul>
        </div>
      </nav>
      <div @click="toggleViewMode = !toggleViewMode"
           class="overlay" :class="[toggleViewMode?'show':'']"
      ></div>
    </header>
  </div>
</template>
<script>
import NavList from "@/components/common-layout/NavList.vue";

export default {
  name: 'DefaultHeader',
  data() {
    return {
      toggleViewMode: false,
    }
  },
  components: {
    NavList
  },
  async created() {

  },

  methods: {
    goToRoute: async function () {
      window.location.replace(`https://care.healthplus.life`);
    },
    onClick: function () {
      this.toggleViewMode = !this.toggleViewMode
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  @apply fixed z-50 w-full left-0 top-0 bg-hp-green-100 shadow-hp-shadow-2
  transition transition-all;
}

.navbar {
  @apply py-[13px] flex flex-wrap xl:flex-nowrap items-center justify-between gap-6;
}

.menuBtn {
  @apply bg-hp-white-100 text-hp-blue-100 p-3 text-hp-header-lg;
}

.offcanvas {
  @apply xlMax:bg-white xlMax:fixed xlMax:-right-full xlMax:top-0
  xlMax:max-w-[450px] xlMax:w-full xlMax:h-full xlMax:fixed xlMax:flex-col
  xlMax:transition-all xlMax:duration-500 xlMax:z-50 xl:static xl:flex-grow;
}

.offcanvas.show {
  @apply right-0 xl:static xl:flex-grow #{!important};
}

.offcanvasHeader {
  @apply px-4 py-6 flex justify-between items-center xl:hidden;
}

.offcanvasCloseBtn {
  @apply px-4 py-2 shadow-hp-shadow-1 text-hp-blue-100
}

.offcanvasBody {
  @apply xlMax:h-[calc(100%_-_theme('spacing.14'))]
  flex flex-col justify-between items-start xl:flex-row xl:justify-around xl:items-center xl:h-full w-full
  xlMax:overflow-auto;
}

.offcanvasBody::-webkit-scrollbar {
  @apply hidden w-3 h-3 hover:inline;
}

.offcanvasBody::-webkit-scrollbar-track {
  @apply hidden w-3 h-3 hover:block;
}

.overlay {
  @apply xlMax:bg-hp-black-100/30 xlMax:fixed xlMax:inset-0
  xlMax:w-full xlMax:h-full xlMax:transition-all xlMax:duration-500
  xlMax:z-40 xlMax:hidden xl:hidden;
}

.overlay.show {
  @apply xlMax:block;
}

.navbarNav {
  @apply flex flex-row gap-5 items-center;
}

.navItem {
  @apply cursor-pointer text-hp-lead text-hp-gray-100;
}

.navItem.activeLink {
  @apply text-hp-lead text-hp-black-100 border-b-2 border-b-hp-green-100 #{!important};
}

.navItem.activeLink .navLInkIcon {
  @apply text-hp-green-100 #{!important};
}

.navLInk {
  @apply flex gap-1 items-center;
}

.callBtn {
  @apply drop-shadow-[0_5px_5px_rgba(0,0,0,0.4)]
}

.searchBtn {
  @apply flex justify-center items-center border-2 border-hp-white-100 py-4 px-8
  text-hp-blue-100 text-hp-body-p1 rounded-[40px]
}

.subscribeNowBtn {
  @apply flex justify-center items-center bg-hp-white-100 py-4 px-8
  text-hp-blue-100 text-hp-lead rounded-[40px]
}

//dropDown
.dropDown {
  @apply hidden relative;
}

.avatar {
  @apply w-12 h-12 bg-hp-gray-50 rounded-full
}

.dropDownToggle {
  @apply flex items-center gap-2 cursor-pointer;
}

.dropDownMenu {
  @apply bg-hp-black-100 absolute top-full end-0 min-w-[250px] w-full hidden transition transition-all;
}

.dropDownMenuList {
  @apply px-6 py-4 text-white text-hp-body-p1 bg-hp-black-100
  hover:text-hp-dark-100 hover:bg-hp-green-100 transition transition-all
  cursor-pointer;
}

</style>