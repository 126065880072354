<template>
  <DefaultHeader/>
  <div class="main-body">
    <router-view :key="$route.fullPath"></router-view>
  </div>
  <DefaultFooter/>
</template>

<script>

import DefaultHeader from "@/components/common-layout/DefaultHeader.vue";
import DefaultFooter from "@/components/common-layout/DefaultFooter.vue";

export default {
  name: 'App',
  components: {DefaultHeader, DefaultFooter},
}
</script>

<style lang="scss">
@import "assets/styles/globals.scss";
</style>
