import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/components/Home/template/HomePage.vue';
import NotFound from "@/components/NotFound.vue";
import PackageSubscription from "@/components/PackageSubscription.vue";
import OnDemandPackSubscription from "@/components/OnDemandPackSubscription.vue";


const routes = [
    {path: '/', redirect: {name: "Home"}},
    {path: '/home', name: "Home", component: Home},
    {path: '/subscription', name: "PackageSubscription", component: PackageSubscription},
    {path: '/on-demand-Subscription', name: "OnDemandPackSubscription", component: OnDemandPackSubscription},
    {path: '/404-not-found', name: "404Page", component: NotFound},
    {path: '/:pathMatch(.*)*', redirect: {name: '404Page'}}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0, behavior: 'smooth'}
    },
})

export default router
