<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "DefaultFooter",
  methods: {
    goToRoute: function (path) {
      window.location.replace(`https://care.healthplus.life${path}`);
    }
  }
})
</script>

<template>
  <div class="footer">
    <div class="container">
      <div class="footerBody">
        <div class="col-span-12 sm:col-span-6 lg:col-span-4">
          <div class="flex gap-4 items-center mb-6">
            <img class="bg-white" src="../../assets/images/HealthPluslogo.png"
                 width="46" height="46" alt="logo">
            <h1 class="text-hp-dark-100 text-hp-body-p2-hi">
              Health Plus
            </h1>
          </div>
          <ul class="footerLinkList">
            <!--            {/*<li>1429 Something Bridge, LA 4281</li>*/}-->
            <li class="text-hp-dark-100">
              <a href="tel:28477">Call: 28477</a>
            </li>
            <li class="flex gap-5">
              <a
                  class="socialLink"
                  href="https://www.facebook.com/healthplusservice"
                  target="_blank"
              >
                <i class="bi bi-facebook"></i>
              </a>
              <a
                  class="socialLink"
                  href="https://www.youtube.com/channel/UC4WiJbYZpR54CbuT5AiPghw"
                  target="_blank"
              >
                <i class="bi bi-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-span-6 sm:col-span-6 lg:col-span-2">
          <h1 class="footerBodyHeading">Explore</h1>
          <ul class="footerLinkList">
            <li>
              <a @click="goToRoute('/about')">About us</a>
            </li>
            <li>
              <a @click="goToRoute('/more')">FAQs</a>
            </li>
          </ul>
        </div>
        <div class="col-span-6 sm:col-span-6 lg:col-span-2">
          <h1 class="footerBodyHeading">Legal</h1>
          <ul class="footerLinkList">
            <li>
              <a @click="goToRoute('/privacy-policy')">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="tel:28477">Help Center</a>
            </li>
          </ul>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-4">
          <h1 class="footerBodyHeading">Subscribe</h1>
          <ul class="footerLinkList">
            <li>Subscribe to get the latest news from us</li>
            <li>
              <div class="relative">
                <input
                    class="subscribeInput"
                    placeholder="Your Email"
                />
                <button class="subscribeBtn">Subscribe</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <hr class="border-hp-dark-100/10 my-10"/>
      <div class="flex sm:items-center flex-col sm:flex-row justify-between gap-4">
        <div class="text-hp-dark-100/30 text-hp-body-p3-hi">
          © {{ new Date().getFullYear() }} Robihealthplus, All Rights Reserved
        </div>
        <div>
          <ul
              class="footerLinkList !flex-row flex-wrap !gap-8"
          >
            <li>
              <a @click="goToRoute('/privacy-policy')">
                Privacy Policy
              </a>
            </li>
            <li>
              <a @click="goToRoute('/about')">About us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer {
  @apply py-12 bg-hp-bg-100
}

.footerBody {
  @apply w-full grid grid-cols-12 gap-y-6;
}

.footerBodyHeading {
  @apply text-hp-header-xs md:text-hp-header-sm lg:text-hp-header-lg text-hp-dark-100 mb-8
}

.footerLinkList {
  @apply flex flex-col gap-4 text-hp-body-p4 text-hp-dark-100/60
}

.socialLink {
  @apply w-12 h-12 rounded-full bg-hp-dark-100/10 flex justify-center items-center text-hp-dark-100/70
}

.subscribeInput {
  @apply w-full block bg-hp-gray-100/70 rounded-full border-0 focus:ring-0 py-4 ps-6
  placeholder-hp-dark-100/50 pe-32
}

.subscribeBtn {
  @apply bg-hp-green-100 text-hp-body-p3-hi text-hp-blue-100 px-5 absolute
  end-0 top-0 h-full rounded-full
}
</style>