<template>
  <div class="main-body bg-hp-green-100 flex flex-col justify-center">
    <div class="container flex flex-col sm:flex-row sm:items-center">
      <div class="w-full order-1">
        <h1 class="font-black text-hp-blue-100 text-5xl mb-3">Oops...</h1>
        <h2 class="font-medium text-hp-blue-100 text-4xl mb-3">
          Page not found
        </h2>
        <p class="text-hp-body-p1 text-hp-blue-100 mb-4">
          The page you are looking for does not exist or some other error
          occurred, go back to home page.
        </p>
        <button
            @click="$router.back()"
            class="text-hp-lead text-hp-blue-100 bg-white rounded-full px-12 py-3"
        >
          Go Back
        </button>
      </div>
      <div class="w-full order-2">
        <picture>
          <img src="../assets/images/error.png" alt=""/>
        </picture>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  async created() {
    await this.getMsisdn();
  },
  methods: {
    getMsisdn: async function () {
      let config = {
        method: "GET",
        url: this.$BASE_URL + this.$V3 + 'user-info/msisdn'
      }
      // this.isLoading = true
      this.$axios(config).then(response => {
        this.isLoading = false;
        // console.log('kkk=', response)
        if (response.status === 200) {
          if (response.data.found === true) {
            localStorage.setItem("msisdn_found", response.data.found);
            localStorage.setItem("user_msisdn", response.data.msisdn);
            window.location.replace(
                `https://care.healthplus.life?found=${response.data.found}&msisdn=${response.data.msisdn}`,
            );
          } else {
            window.location.replace(`https://care.healthplus.life`);
          }
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      })
    }
  },
}
</script>
<style scoped>

</style>
