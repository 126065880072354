<script>
import Loading from "vue-loading-overlay";

export default {
  name: "PackageSubscription",
  components: { Loading },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "spinner",
      packageData: [],
      ondemandPackageData: [],
    };
  },
  async created() {
    // if (!localStorage.getItem('user_msisdn')){
    //   localStorage.setItem("user_msisdn", this.$route.query.msisdn);
    // }
    localStorage.setItem("msisdn_found", this.$route.query.msisdn_found);
    localStorage.setItem("user_msisdn", this.$route.query.msisdn);
    await this.getPackageData();
    await this.getOndenandPackages();
    if (!this.$route.query.msisdn || !this.$route.query.msisdn_found)
      await this.getMsisdn();
  },

  methods: {
    getMsisdn: async function () {
      this.isLoading = true;
      let config = {
        method: "GET",
        url: this.$BASE_URL + this.$V3 + "user-info/msisdn",
      };
      // this.isLoading = true
      this.$axios(config)
        .then((response) => {
          this.isLoading = false;
          if (response.status === 200) {
            if (response.data.found === true) {
              localStorage.setItem("msisdn_found", response.data.found);
              localStorage.setItem("user_msisdn", response.data.msisdn);
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    getPackageData: async function () {
      let config = {
        method: "GET",
        url: this.$BASE_URL + this.$V6 + "packages/?channel:_in=WEB&sort=price",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          msisdn: `${localStorage.getItem("user_msisdn")}`,
        },
      };
      // this.isLoading = true
      this.$axios(config)
        .then((response) => {
          this.isLoading = false;
          // console.log('kkk=', response)
          if (response.status === 200) {
            this.packageData = response.data.data;
            // console.log('kkk=', response.data.data)
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    getOndenandPackages: async function () {
      let config = {
        method: "GET",
        url: this.$BASE_URL2 + this.$V6 + "packages/ondemand-package",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          msisdn: `${localStorage.getItem("user_msisdn")}`,
        },
      };
      // this.isLoading = true
      this.$axios(config)
        .then((response) => {
          this.isLoading = false;
          // console.log('kkk=', response)
          if (response.status === 200) {
            this.ondemandPackageData = response.data.data;
            // console.log('kkk=', response.data.data)
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    Subscribe: async function (param) {
      // let msisdn = this.$route.query.msisdn;
      let msisdn_found = localStorage.getItem("msisdn_found");
      this.isLoading = true;

      let query = `?package_name=${param?.packagename}&msisdn_found=${msisdn_found}`;
      let config = {
        method: "GET",
        url: this.$BASE_URL + this.$V1 + "subscriptions/user-consent" + query,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          msisdn: `${localStorage.getItem("user_msisdn")}`,
        },
      };
      this.$axios(config)
        .then((response) => {
          this.isLoading = false;
          console.log("fasdfas dfasdfas=", response.data);
          if (response?.status === 200 && response.data?.redirectUrl) {
            // window.open(data?.redirectUrl, "_BLANK");
            window.location.replace(response.data?.redirectUrl);
          } else {
            this.$swal.fire({
              title: response.data.type,
              text: response.data.error.message,
              icon: "warning",
              timer: 4000,
            });
            window.location.replace(`https://care.healthplus.life/login`);
          }
        })
        .catch(async (error) => {
          this.isLoading = false;
          await this.$swal.fire({
            title: error.response.data.type,
            text: error.response.data.error.message,
            icon: "warning",
            timer: 4000,
          });
          window.location.replace(`https://care.healthplus.life/login`);
          console.log(error);
        });
    },
    OndemandSubscribe: async function (param) {
      await this.$swal
        .fire({
          title: "Do you want to subscribe to this package?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#BCEDB6",
          cancelButtonColor: "#EA5252",
          confirmButtonText: "Subscribe",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            const msisdn = localStorage.getItem("user_msisdn");
            console.log(msisdn);
            const myHeaders = new Headers();
            // myHeaders.append("Content-Type", "application/json");
            myHeaders.append("msisdn", msisdn);
            let config = {
              method: "POST",
              url:
                this.$BASE_URL +
                this.$V1 +
                "subscriptions/ondemand-subscribe/" +
                param.packageName,
              headers: { msisdn: msisdn },
            };
            this.$axios(config)
              .then((response) => {
                this.isLoading = false;
                if (response.status === 200) {
                  window.location.replace(`https://care.healthplus.life/login`);
                } else {
                  this.$swal.fire({
                    title: response.data.type,
                    text: response.data.error.message,
                    icon: "warning",
                    timer: 4000,
                  });
                }
              })
              .catch(async (error) => {
                this.isLoading = false;
                await this.$swal.fire({
                  title: error.response.data.type,
                  text: error.response.data.error.message,
                  icon: "warning",
                  timer: 4000,
                });
                window.location.replace(`https://care.healthplus.life/login`);
                console.log(error);
              });
          }
        });
    },
  },
};
</script>

<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <section class="py-4 container">
    <h1 class="text-hp-display-xs lg:text-hp-display-lg mb-5">Regular Pack</h1>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-5 mb-5">
      <div
        v-for="elem in packageData"
        :key="elem?.id"
        class="bg-white hover:bg-[#BCEDB6] p-5 rounded-md shadow-sm hover:shadow-lg md:hover:scale-x-110 md:hover:scale-y-125 transition-all duration-500"
      >
        <p
          class="bg-[#BCEDB6] border-2 border-white font-semibold px-6 py-2 text-sm md:text-lg text-center rounded-lg"
        >
          {{ elem?.packagedisplay }}
        </p>
        <p
          class="text-blue-900 text-sm md:text-lg font-medium py-10 px-5 text-center"
        >
          {{ elem?.priceDisplay }}
        </p>
        <div class="flex justify-center">
          <button
            @click="Subscribe(elem)"
            class="px-10 py-2 bg-[#0F2851] rounded-xl text-white text-sm md:text-lg font-semibold"
          >
            Subscribe Now
          </button>
        </div>
      </div>
    </div>
    <h1 class="text-hp-display-xs lg:text-hp-display-lg mb-5">
      On-Demand Pack
    </h1>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-5">
      <div
        v-for="elem in ondemandPackageData"
        :key="elem?.id"
        class="bg-white hover:bg-[#BCEDB6] p-5 rounded-md shadow-sm hover:shadow-lg md:hover:scale-x-110 md:hover:scale-y-125 transition-all duration-500"
      >
        <p
          class="bg-[#BCEDB6] border-2 border-white font-semibold px-6 py-2 text-sm md:text-lg text-center rounded-lg"
        >
          {{ elem?.packageDisplay }}
        </p>
        <p
          class="text-blue-900 text-sm md:text-lg font-medium pt-10 px-5 text-center"
        >
          {{ elem?.priceDisplay }} On Demand
        </p>
        <p class="pb-10 text-sm text-center">
          Package Validity {{ elem.packageValidity }}{{ " " }}
          {{ elem.packageValidity > 1 ? "days" : "day" }}
        </p>
        <div class="flex justify-center">
          <button
            @click="OndemandSubscribe(elem)"
            class="px-10 py-2 bg-[#0F2851] rounded-xl text-white text-sm md:text-lg font-semibold"
          >
            Subscribe Now
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss"></style>
